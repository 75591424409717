//export const API_BASE_URL = 'https://chakkizza-reports-backend.herokuapp.com';
// export const API_BASE_URL = 'http://localhost:8080';
export const API_BASE_URL = 'https://api.chakkizza.xyz';
//'http://localhost:8080'
//Heroku: 'https://chakkizza-reports-backend.herokuapp.com'

export const ACCESS_TOKEN = 'accessToken';

export const OAUTH2_REDIRECT_URI = 'http://www.chakkizza.xyz/oauth2/redirect';
//export const OAUTH2_REDIRECT_URI = 'https://chakkizza-reports-frontend.herokuapp.com/oauth2/redirect';
// export const OAUTH2_REDIRECT_URI = 'http://localhost:3000/oauth2/redirect';


export const GOOGLE_AUTH_URL = API_BASE_URL + '/oauth2/authorize/google?redirect_uri=' + OAUTH2_REDIRECT_URI;
//export const FACEBOOK_AUTH_URL = API_BASE_URL + '/oauth2/authorize/facebook?redirect_uri=' + OAUTH2_REDIRECT_URI;
//export const GITHUB_AUTH_URL = API_BASE_URL + '/oauth2/authorize/github?redirect_uri=' + OAUTH2_REDIRECT_URI;
