import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { MenuList1 } from '../constants/MenuList';
import './Home.css';

class Home extends Component {
    render() {
        const menuItems = this.props.currentUser ? MenuList1[this.props.currentUser.role] : MenuList1["loggedOut"];
        return (
            <div className="home-container">
                <div className="container">
                    <ol>
                        {menuItems.map((item, index) => (
                            <li key={item?.name || index} style={{ marginBottom: 10 }}><NavLink to={item.path}>{item.name}</NavLink></li>
                        ))}
                    </ol>
                </div>
            </div>
        )
    }
}

export default Home;