import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

// @mui
import MaterialTable, { MTableBodyRow } from '@material-table/core';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import DownloadIcon from '@mui/icons-material/Download';
import { Button, IconButton, Stack } from '@mui/material';
// components

// hooks
import { downloadReportFile, getReportFiles } from '../../util/APIUtils';
import { fDateTimeSuffix } from '../../util/formatTime';

// -----------------------------------------------

const Toolbar = () => <div />
const Pagination = (props) =>
  <TablePagination
    {...props}
    sx={{
      p: 0, fontSize: 12,
      '& .MuiTablePagination-root': {
        fontSize: '12px'
      },
      '& .MuiTablePagination-displayedRows': {
        fontSize: '12px'
      },
      '& .MuiTablePagination-selectLabel': {
        fontSize: '12px'
      }
    }}
  />
const Container = props => <Box {...props} className='mtable' />
const Row = props => <MTableBodyRow {...props} />

// -----------------------------------------------

const FilesTable = ({ reportDetails }) => {

  const [rows, setRows] = useState([]);


  const getReports = async () => {
    const result = await getReportFiles(reportDetails.reportName);
    setRows(result?.data || [])
  };

  useEffect(() => {

    if (reportDetails?.reportName) {
      getReports()
    }

  }, [reportDetails]);

  const downloadFile = async (fileName) => {
    try {
      const result = await downloadReportFile(reportDetails.reportName, fileName);
    } catch (error) {
      console.log(error)
      alert("File not found")
    }
  }


  const COLUMNS = [
    {
      field: "name",
      title: "File name",
      // sx: { minWidth: 150 }
    },
    {
      field: "size",
      title: "Size",
      align: 'right',
      // width: 50,
      render: (rowData) => `${(rowData.size / 1024).toFixed(1)}kB`

    },
    {
      field: "lastModified",
      title: "Generated on",
      align: 'right',
      render: rowData => fDateTimeSuffix(new Date(rowData.lastModified)),
      // width: 210,
    },
    {
      field: "",
      title: "",
      align: 'right',
      render: (rowData) =>
        <IconButton size="medium" color='primary'
          onClick={() => downloadFile(rowData.name)}
        >
          <DownloadIcon />
        </IconButton>
      // width: 210,
    },
  ];

  return (
    <Paper sx={{ my: 4, borderRadius: 1, overflow: 'auto' }}>
      <Stack sx={{ p: 1 }}>
        <Button
          id='button-refresh'
          variant="contained"
          onClick={() => getReports()}
          sx={{ alignSelf: 'flex-end', textTransform: 'none' }}
        >
          Refresh List
        </Button>
      </Stack>
      <MaterialTable
        title=""
        columns={COLUMNS}
        data={rows}
        components={{
          Toolbar,
          Pagination,
          Container,
          Row
        }}
        style={{
          border: 'none',
          background: 'transparent',
        }}

        options={{
          search: false,
          // padding: 'dense',
          tableLayout: 'auto',
          emptyRowsWhenPaging: false,
          // paging config
          paging: true,
          pageSize: 20,
          pageSizeOptions: [20, 50, 100],
          // paging end
          maxBodyHeight: '40vh',
          headerStyle: {
            backgroundColor: (theme) => theme.palette.grey[200],
            position: 'sticky', top: 0,
            fontSize: 12,
            fontWeight: 'bold',
            zIndex: 1,
            paddingTop: 10

          },
          rowStyle: {
            fontSize: 12,
          },
        }}
      />
    </Paper>)
};

export default FilesTable;


FilesTable.propTypes = {
  reportDetails: PropTypes.object
};




