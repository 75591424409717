import React, { Component } from 'react';
import LoadingIndicator from '../component/common/LoadingIndicator';
import Button from '../component/UI/Button/Button';
import Input from '../component/UI/Input/Input';
import './RoutingForm.css';
//import Button from '@mui/material/Button';


class RoutingForm extends Component {
    constructor(props) {
        super(props);
        let routingForm;
        let reportFormat;

        routingForm = {
            deliveryDate: {
                elementType: 'input',
                elementConfig: {
                    type: 'date',
                    placeholder: 'Delivery Date:'
                },
                value: new Date().toISOString().split("T")[0],
                validation: {
                    required: true
                },
                valid: true,
                touched: true
            }
        };
        reportFormat = 'pdf';
        this.state = {
            routingForm: routingForm,
            formIsValid: true, //uncomment if parameters are added//Object.keys(routingForm).length === 0 && routingForm.constructor === Object ? true : false,
            loading: false,
            //reportName: this.props.location.formProps.reportName,
            reportFormat: reportFormat
        };
    }

    componentDidMount() {
        //console.log('form mounted.')
    }
    generateReport = (event) => {
        event.preventDefault();
        this.setState({ loading: true });
        const formData = {};
        for (let formElementIdentifier in this.state.routingForm) {
            formData[formElementIdentifier] = this.state.routingForm[formElementIdentifier].value;
        }
        //console.log('formData: ', formData);
        //console.log('deliveryDate ', formData.deliveryDate);
        this.props.history.push({
            pathname: '/routingList',
            formProps: { deliveryDate: formData.deliveryDate }
        });
    }

    checkValidity(value, rules) {
        let isValid = true;
        if (!rules) {
            return true;
        }

        if (rules.required) {
            isValid = value.trim() !== '' && isValid;
        }

        if (rules.minLength) {
            isValid = value.length >= rules.minLength && isValid
        }

        if (rules.maxLength) {
            isValid = value.length <= rules.maxLength && isValid
        }

        if (rules.isEmail) {
            const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
            isValid = pattern.test(value) && isValid
        }

        if (rules.isNumeric) {
            const pattern = /^\d+$/;
            isValid = pattern.test(value) && isValid
        }

        return isValid;
    }

    inputChangedHandler = (event, inputIdentifier) => {
        const updatedRoutingForm = {
            ...this.state.routingForm
        };
        const updatedFormElement = {
            ...updatedRoutingForm[inputIdentifier]
        };
        updatedFormElement.value = event.target.value;
        updatedFormElement.valid = this.checkValidity(updatedFormElement.value, updatedFormElement.validation);
        updatedFormElement.touched = true;
        updatedRoutingForm[inputIdentifier] = updatedFormElement;

        let formIsValid = true;
        for (let inputIdentifier in updatedRoutingForm) {
            formIsValid = updatedRoutingForm[inputIdentifier].valid && formIsValid;
        }
        this.setState({ routingForm: updatedRoutingForm, formIsValid: formIsValid });
    }

    render() {
        const formElementsArray = [];
        for (let key in this.state.routingForm) {
            formElementsArray.push({
                id: key,
                config: this.state.routingForm[key]
            });
        }
        let form = (
            <form onSubmit={this.generateReport}>
                {formElementsArray.map(formElement => {
                    return (
                        <div key={formElement.id}>
                            <label className="input-label">{formElement.config.elementConfig.placeholder}</label>
                            <Input
                                elementType={formElement.config.elementType}
                                elementConfig={formElement.config.elementConfig}
                                value={formElement.config.value}
                                invalid={!formElement.config.valid}
                                shouldValidate={formElement.config.validation}
                                touched={formElement.config.touched}
                                changed={(event) => this.inputChangedHandler(event, formElement.id)} />
                        </div>
                    )
                }
                )}
                <Button btnType="Success" disabled={!this.state.formIsValid}>Submit</Button>
                {/* <Button variant="contained" disabled={!this.state.formIsValid} style={{ marginTop: 10 }} >Submit</Button> */}
            </form>
        );
        if (this.state.loading) {
            form = <LoadingIndicator />;
        }
        return (
            <div className="report-container">
                <div className="report-content">
                    <h1 className="report-title">View Routing</h1>
                    <div className="report-form">
                        {form}
                    </div>
                </div>
            </div>
        );
    }
}

export default RoutingForm;


//orderform example:
/* routingForm: {
           name: {
               elementType: 'input',
               elementConfig: {
                   type: 'text',
                   placeholder: 'Your Name'
               },
               value: '',
               validation: {
                   required: true
               },
               valid: false,
               touched: false
           },
           street: {
               elementType: 'input',
               elementConfig: {
                   type: 'text',
                   placeholder: 'Street'
               },
               value: '',
               validation: {
                   required: true
               },
               valid: false,
               touched: false
           },
           zipCode: {
               elementType: 'input',
               elementConfig: {
                   type: 'text',
                   placeholder: 'ZIP Code'
               },
               value: '',
               validation: {
                   required: true,
                   minLength: 5,
                   maxLength: 5,
                   isNumeric: true
               },
               valid: false,
               touched: false
           },
           country: {
               elementType: 'input',
               elementConfig: {
                   type: 'text',
                   placeholder: 'Country'
               },
               value: '',
               validation: {
                   required: true
               },
               valid: false,
               touched: false
           },
           email: {
               elementType: 'input',
               elementConfig: {
                   type: 'email',
                   placeholder: 'Your E-Mail'
               },
               value: '',
               validation: {
                   required: true,
                   isEmail: true
               },
               valid: false,
               touched: false
           },
           deliveryMethod: {
               elementType: 'select',
               elementConfig: {
                   options: [
                       { value: 'fastest', displayValue: 'Fastest' },
                       { value: 'cheapest', displayValue: 'Cheapest' }
                   ]
               },
               value: '',
               validation: {},
               valid: true
           }
       }, */