import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './app/App';
import './index.css';
//import registerServiceWorker from './registerServiceWorker';
import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import { BrowserRouter as Router } from 'react-router-dom';
// import ThemeProvider from './theme';
// import NotistackProvider from './components/others/NotistackProvider';

const container = document.getElementById('root');
const root = createRoot(container);
const theme = createTheme();

root.render(<Router>
    <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
            <SnackbarProvider anchorOrigin={{ horizontal: 'right', vertical: 'top' }}>
                <App />
            </SnackbarProvider>
        </ThemeProvider>
    </StyledEngineProvider>
</Router>);
//registerServiceWorker();
