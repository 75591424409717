import { ACCESS_TOKEN, API_BASE_URL } from '../constants';

export const request = (options) => {
    const headers = new Headers({
        'Content-Type': 'application/json',
    })

    if (localStorage.getItem(ACCESS_TOKEN)) {
        headers.append('Authorization', 'Bearer ' + localStorage.getItem(ACCESS_TOKEN))
    }

    const defaults = { headers: headers };
    options = Object.assign({}, defaults, options);

    return fetch(options.url, options)
        .then(response =>
            response.json().then(json => {
                if (!response.ok) {
                    return Promise.reject(json);
                }
                return json;
            })
        );
};

export function getCurrentUser() {
    if (!localStorage.getItem(ACCESS_TOKEN)) {
        return Promise.reject("No access token set.");
    }

    //
    return request({
        url: API_BASE_URL + "/user/me",
        method: 'GET'
    });
}

export function login(loginRequest) {
    return request({
        url: API_BASE_URL + "/auth/login",
        method: 'POST',
        body: JSON.stringify(loginRequest)
    });
}

export function signup(signupRequest) {
    return request({
        url: API_BASE_URL + "/auth/signup",
        method: 'POST',
        body: JSON.stringify(signupRequest)
    });
}

export function getReportList(requestBody) {

    if (!localStorage.getItem(ACCESS_TOKEN)) {
        return Promise.reject("No access token set.");
    }

    //console.log(localStorage.getItem(ACCESS_TOKEN));

    return request({
        url: API_BASE_URL + "/Checklists",
        method: 'GET',
        body: JSON.stringify(requestBody)
    });
}

export function getRoutes(date) {

    if (!localStorage.getItem(ACCESS_TOKEN)) {
        return Promise.reject("No access token set.");
    }

    //console.log(localStorage.getItem(ACCESS_TOKEN));

    return getRoutesRequest({
        url: API_BASE_URL + `/routes?date=${date}`,
        method: 'GET',
        //body: requestBody
    });
}


export const getRoutesRequest = (options) => {
    const headers = new Headers({
    })

    if (localStorage.getItem(ACCESS_TOKEN)) {
        headers.append('Authorization', 'Bearer ' + localStorage.getItem(ACCESS_TOKEN))
    }

    const defaults = { headers: headers };
    options = Object.assign({}, defaults, options);

    return fetch(options.url, options)
        .then(response =>
            response.json().then(json => {
                if (!response.ok) {
                    return Promise.reject(json);
                }
                return json;
            })
        );
};

export function uploadFile(uploadFileRequest) {

    if (!localStorage.getItem(ACCESS_TOKEN)) {
        return Promise.reject("No access token set.");
    }

    //console.log(localStorage.getItem(ACCESS_TOKEN));

    return uploadRequest({
        url: API_BASE_URL + "/upload",
        method: 'POST',
        body: uploadFileRequest
    });
}

export function uploadReconFile(uploadFileRequest) {

    if (!localStorage.getItem(ACCESS_TOKEN)) {
        return Promise.reject("No access token set.");
    }

    //console.log(localStorage.getItem(ACCESS_TOKEN));

    return uploadRequest({
        url: API_BASE_URL + "/uploadRecon",
        method: 'POST',
        body: uploadFileRequest
    });
}

export const uploadRequest = (options) => {
    const headers = new Headers({
        //'Content-Type': 'application/json',
        //'accepts': 'text/*'
    })

    if (localStorage.getItem(ACCESS_TOKEN)) {
        headers.append('Authorization', 'Bearer ' + localStorage.getItem(ACCESS_TOKEN))
    }

    const defaults = { headers: headers };
    options = Object.assign({}, defaults, options);

    return fetch(options.url, options)
        .then(response => {
            if (!response.ok) {
                return Promise.reject(response);
            }
            return response;
        }).catch(error => {
            return Promise.reject(error);
        });
};

export function getReport(getReportRequest) {

    if (!localStorage.getItem(ACCESS_TOKEN)) {
        return Promise.reject("No access token set.");
    }
    //

    return downloadRequest({
        url: API_BASE_URL + "/Checklists/export",
        method: 'POST',
        body: JSON.stringify(getReportRequest)
    });
}

export function generateReportAsync(getReportRequest) {

    if (!localStorage.getItem(ACCESS_TOKEN)) {
        return Promise.reject("No access token set.");
    }

    return request({
        url: API_BASE_URL + "/Checklists/exportasync",
        method: 'POST',
        body: JSON.stringify(getReportRequest)
    });
}

export function getReportFiles(reportName) {
    if (!localStorage.getItem(ACCESS_TOKEN)) {
        return Promise.reject("No access token set.");
    }

    return request({
        url: API_BASE_URL + `/listfiles?reportname=${reportName}`,
        method: 'GET'
    });
}

export function downloadReportFile(reportName, fileName) {
    if (!localStorage.getItem(ACCESS_TOKEN)) {
        return Promise.reject("No access token set.");
    }

    return downloadRequest({
        url: API_BASE_URL + `/downloadFile/${reportName}/${fileName}`,
        method: 'GET'
    })
}


export const downloadRequest = (options) => {
    const headers = new Headers({
        'Content-Type': 'application/json',
    })

    if (localStorage.getItem(ACCESS_TOKEN)) {
        headers.append('Authorization', 'Bearer ' + localStorage.getItem(ACCESS_TOKEN))
    }

    const defaults = { headers: headers };
    options = Object.assign({}, defaults, options);

    return fetch(options.url, options)
        .then(response => {
            if (!response.ok) throw new Error(response.status, response.error);
            else {
                response.blob().then(blob => {
                    if (!response.ok) {
                        return Promise.reject(blob);
                    }
                    // Create blob link to download

                    //console.log(response.headers.get("Content-Disposition"));
                    const temp = response.headers.get("Content-Disposition");
                    //console.log(temp.split('filename=')[1].replace(/['"]+/g, ''))
                    const filename = temp.split('filename=')[1].replace(/['"]+/g, '');
                    const url = window.URL.createObjectURL(
                        new Blob([blob]),
                    );
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute(
                        'download',
                        filename
                    );

                    // Append to html link element page
                    document.body.appendChild(link);

                    // Start download
                    link.click();

                    // Clean up and remove the link
                    link.parentNode.removeChild(link);
                    return blob;
                })

            }
        }
        );
};
/* export const getReport = (options) => {
    const headers = new Headers({
        'Content-Type': 'application/json',
    })

    if(localStorage.getItem(ACCESS_TOKEN)) {
        headers.append('Authorization', 'Bearer ' + localStorage.getItem(ACCESS_TOKEN))
    }

    const defaults = {headers: headers};
    options = Object.assign({}, defaults, options);

    return fetch(options.url, options)
    .then(response =>
        response.json().then(json => {
            if(!response.ok) {
                return Promise.reject(json);
            }
            return json;
        })
    );
}; */