import {
  format,
  getTime,
  formatDistanceToNow,
  subYears,
  addYears,
  subDays,
  addDays
} from 'date-fns';

// -----------------------------------------------

// add days to currecnt date
export function daysAdd(amount) {
  return addDays(new Date(), amount);
}

// subtract days from currecnt date
export function daysSubtract(amount) {
  return subDays(new Date(), amount);
}

// subtract year from currecnt date
export function yearSubtract(amount) {
  return subYears(new Date(), amount);
}
// add year to current date
export function yearAdd(amount) {
  return addYears(new Date(), amount);
}


export function fDate(date) {
  if (!date) {
    return '';
  }
  return format(new Date(date), 'dd MMM yyyy');
}

export function fDateMonth(date) {
  if (!date) {
    return '';
  }
  if (typeof date === 'object') {
    return format(date, 'dd MMM');
  }
  return format(new Date(date), 'dd MMM');
}

export function fDateTime(date) {
  if (!date) {
    return '';
  }
  return format(new Date(date), 'dd MMM yyyy HH:mm');
}

export function fTimestamp(date) {
  return getTime(new Date(date));
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd MMM yyyy p');
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true
  });
}


export function fDate2(date) {
  if (typeof date !== 'object') {
    return format(new Date(date), 'yyyy-MM-dd');
  }
  return format(date, 'yyyy-MM-dd');
}

// ---display time from given obj or string---
// input -> '2014-08-18T13:17:00'
// output -> '1:17 PM'
export const displayTime = (date) => {
  if (!date) return "";

  if (typeof date !== 'object') {
    // return new Date(date).toLocaleTimeString();
    return format(new Date(date), 'hh:mm a');
  }

  // return date.toLocaleTimeString();
  return format(date, 'hh:mm a');
}
