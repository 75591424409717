import Board, { moveCard } from "@asseinfo/react-kanban";
import "@asseinfo/react-kanban/dist/styles.css";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import React, { Component } from 'react';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import LoadingIndicator from '../component/common/LoadingIndicator';
import { getRoutes } from '../util/APIUtils';
import './RoutingList.css';


class RoutingList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            batches: {
                columns: [
                    {
                        id: 0,
                        title: "Orders not routed",
                        cards: []
                    }
                ]
            },
            routeList: [],
            deliveryDate: this.props.location.formProps.deliveryDate,
        };
    }

    componentDidMount() {
        //console.log(this.state.deliveryDate)
        this.setState({ loading: true });
        getRoutes(this.state.deliveryDate)
            .then(response => {
                //console.log('getRoutes API response', response);
                if (response.success == 1) {
                    const batches = this.state.batches;
                    batches.columns = response.data;
                    this.setState({ batches: batches, loading: false })
                    //this.setBatches(response);
                } else if (response.success == 0 && response.error) {
                    this.setState({ loading: false })
                    alert(response.error);
                    this.props.history.push({
                        pathname: '/viewRouting'
                    });
                }
            }).catch(error => {
                this.setState({ loading: false });
                alert((error && error.message) || 'Oops! Something went wrong. Please try again!');
            });

    }

    setBatches(routeList) {
        const batches = {
            columns: [
                {
                    id: 0,
                    title: "Orders",
                    cards: []
                }
            ]
        };

        routeList.forEach((order) => {
            if (!order.batch) {
                batches.columns[0].cards.push({
                    id: order.orderId,
                    title: order.custName,
                    description: order.address
                })
            } else {
                //console.log("order.batch", order.batch);
                //console.log("order.orderId", order.orderId);
                !batches.columns[order.batch] ? batches.columns.push({
                    id: order.batch,
                    title: "Batch" + order.batch,
                    cards: []
                }) : null;

                batches.columns[order.batch].cards.push({
                    id: order.orderId,
                    title: order.orderId,//order.custName,
                    description: order.address
                })
            }
            //console.log(order)
        });

        //console.log(batches);
        this.setState({ batches: batches })
    }

    handleCardMove(_card, source, destination) {
        const updatedBatches = moveCard(this.state.batches, source, destination);
        this.setState({ batches: updatedBatches })
    }

    onColumnNew(column) {
        const newColumn = {
            id: 12345, ...column
        }
        return newColumn
    }



    render() {
        if (this.state.loading) {
            return <LoadingIndicator />
        }
        return (
            <div className="home-container">
                <div className="container">
                    <Board
                        //onCardDragEnd={(_card, source, destination) => this.handleCardMove(_card, source, destination)}
                        //allowAddColumn
                        //onColumnNew={(newColumn) => this.onColumnNew(newColumn)}
                        key={({ title }) => (title)}

                        renderColumnHeader={({ title, description }) => (
                            <div >
                                <Typography style={{ marginLeft: 5 }}>{title}</Typography>
                                <Typography variant="body2" color="textSecondary" component="p" style={{ marginLeft: 5 }}>{description}</Typography>
                            </div>
                        )}

                        renderCard={(card) => (
                            <Card style={{ maxWidth: 250, margin: 3 }} key={card.batch_order_id}>
                                {/* <CardHeader
                                    title={"Order ID #" + card.orderId}
                                    subheader={"Weight: " + card.weight}
                                    titleTypographyProps={{ variant: "subtitle1" }}
                                    subheaderTypographyProps={{ variant: "subtitle2" }}
                                /> */}
                                <CardContent style={{ padding: 10 }}>
                                    <Typography color="textPrimary" variant="body1" style={{ marginBottom: 5 }}>{"Order ID #" + card.orderId}</Typography>
                                    <Divider />
                                    <Typography color="initial" variant="subtitle2" style={{ marginTop: 5 }}>{"Weight: " + card.weight + " kg"}</Typography>
                                    <Typography color="initial" variant="subtitle2">Address:</Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        {card.address}
                                    </Typography>
                                </CardContent>
                            </Card>
                        )}
                    >
                        {this.state.batches}
                    </Board>

                    {/* <MapContainer center={[51.505, -0.09]} zoom={13} scrollWheelZoom={false}>
                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <Marker position={[51.505, -0.09]}>
                            <Popup>
                                A pretty CSS3 popup. <br /> Easily customizable.
                            </Popup>
                        </Marker>
                    </MapContainer> */}
                </div>
            </div>
        )
    }
}

export default RoutingList;